/* .m1-find-vehicle-us {
  background: var(--m1-find-vehicle-eu-bg);
  color: #fff;
  padding: 25px;
  position: relative;
  margin-bottom: 10px;
} */

.m1-find-vehicle-us > * {
  display: block;
}

.m1-find-vehicle-mobile .m1-find-vehicle-us {
  margin-bottom: 0;
}

.m1-find-vehicle-us_toggle-button {
  position: absolute;
  top: 28px;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.m1-find-vehicle-us_toggle-button:first-of-type {
  left: 25px;
}

.m1-find-vehicle-us_toggle-button:nth-of-type(2) {
  right: 25px;
}

.m1-find-vehicle-us_toggle-button svg {
  fill: var(--white-color);
  width: 10px;
  height: 10px;
}

/* .m1-find-vehicle-us_toggle-button::before {
  margin: 0;
  padding: 0;
} */

.collapsed .m1-find-vehicle-us_toggle-button {
  -webkit-transform: none;
  transform: none;
}

.collapsed .m1-find-vehicle-us_toggle-button:nth-of-type(2) {
  transform: rotate(180deg);
}

.m1-find-vehicle-us.small .m1-find-vehicle-us_toggle-button {
  display: none !important;
}

.m1-find-vehicle-us .title {
  font-size: 34px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
  margin: 0 0 20px;
  text-align: center;
}

body .m1-find-vehicle-us .title {
  font-size: 31px;
}

body .m1-find-vehicle-us_toggle-button {
  top: 25px;
}

.m1-find-vehicle-us form {
  max-width: none;
  min-width: 0;
}

.m1-find-vehicle-us select {
  padding: 0;
  margin: 0;
  height: 46px;
}

.m1-find-vehicle-us select {
  width: auto !important;
}

.m1-find-vehicle-us:not(.initialized):not(.collapsed) .m1-find-vehicle-us_small-wrapper {
  display: none !important;
}

/* START Switcher */
.m1-find-vehicle-us_type-switcher {
  display: inline-block;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.m1-find-vehicle-us_type-switcher label {
  display: inline-block;
  cursor: pointer;
}

.m1-find-vehicle-us_type-switcher .on,
.m1-find-vehicle-us_type-switcher .off {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  pointer-events: none;
}

.m1-find-vehicle-us_type-switcher .on::before,
.m1-find-vehicle-us_type-switcher .on::after,
.m1-find-vehicle-us_type-switcher .off::before,
.m1-find-vehicle-us_type-switcher .off::after {
  display: none;
}

.m1-find-vehicle-us_type-switcher input {
  display: none;
}

.m1-find-vehicle-us_type-switcher .slider {
  position: relative;
  pointer-events: none;
  cursor: pointer;
  width: 80px;
  height: 42px;
  background-color: #000;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 21px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}

.m1-find-vehicle-us_type-switcher .slider.round::before {
  border-radius: 50%;
}

.m1-find-vehicle-us_type-switcher .slider::before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  left: 6px;
  bottom: 6px;
  background-color: red;
  box-shadow: inset 0 0 0 11px #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.m1-find-vehicle-us_type-switcher .slider {
  box-shadow: inset 0 0 0 2px #fff;
}

.m1-find-vehicle-us_type-switcher input:checked + .slider::before {
  -webkit-transform: translateX(38px);
  -ms-transform: translateX(38px);
  transform: translateX(38px);
}

.m1-find-vehicle-us_type-switcher input:checked + .slider .on {
  display: block;
}

.m1-find-vehicle-us_type-switcher input:checked + .slider .off {
  display: none;
}

/* END Switcher */

.m1-find-vehicle-us.collapsed:not(.initialized) .m1-find-vehicle-us_big-wrapper {
  display: none !important;
}

.m1-find-vehicle-us:not(.show-used) .form-vin {
  display: none;
}

.m1-find-vehicle-us.show-used .form-oem {
  display: none;
}

.m1-find-vehicle-us input[name='zip'] {
  height: 46px;
  margin-bottom: 0 !important;
}

.m1-find-vehicle-us input::-webkit-input-placeholder {
  color: #999;
}

.m1-find-vehicle-us input::-moz-placeholder {
  color: #999;
}

.m1-find-vehicle-us input:-moz-placeholder {
  color: #999;
}

.m1-find-vehicle-us input:-ms-input-placeholder {
  color: #999;
}

.ready-center__m1-find-vehicle .m1-find-vehicle-eu {
  visibility: visible;
  overflow: auto;
  max-height: fit-content;
}

@media screen and (min-width: 1000px) {
  .m1-find-vehicle-us:not(.small) .m1-find-vehicle-us_big {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m1-find-vehicle-us:not(.small) .m1-find-vehicle-us_big section {
    -ms-flex: 1 1 0.000000001px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0.000000001px;
    flex-basis: 0.000000001px;
  }

  .m1-find-vehicle-us:not(.small) section + section {
    margin-left: 25px;
  }

  .m1-find-vehicle-us .m1-find-vehicle-us_big .cars_form_submit {
    margin-top: 15px;
  }

  .m1-find-vehicle-us:not(.small) select ~ select,
  .m1-find-vehicle-us:not(.small) input[name='zip'] {
    margin-left: 15px;
  }

  .m1-find-vehicle-us_toggle-button:first-of-type {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .m1-find-vehicle-us_small {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m1-find-vehicle-us_small .fields {
    text-align: left;
    -ms-flex: 1 1 0.000000001px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0.000000001px;
    flex-basis: 0.000000001px;
  }

  .m1-find-vehicle-us_small input[type='submit'] {
    width: 150px;
    margin: 0 45px 0 15px;
  }

  .m1-find-vehicle-us_small section {
    margin-left: 25px;
    -ms-flex: 1 1 0.000000001px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0.000000001px;
    flex-basis: 0.000000001px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m1-find-vehicle-us_small form {
    -ms-flex: 1 1 0.000000001px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0.000000001px;
    flex-basis: 0.000000001px;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media screen and (min-width: 480px) {
  .m1-find-vehicle-us:not(.small) .fields {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .m1-find-vehicle-us:not(.small) select,
  .m1-find-vehicle-us:not(.small) input[name='zip'] {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

@media screen and (min-width: 575px) {
  .nt-hide-m {
    display: none;
  }

  .hide-m {
    display: block;
  }

  .nt-hide-d {
    display: block !important;
  }

  .hide-d {
    display: none !important;
  }

  .m1-find-vehicle-us_toggle-title {
    pointer-events: none;
  }
}

@media screen and (max-width: 999px) {
  .m1-find-vehicle-us {
    padding: 15px;
  }

  .m1-find-vehicle-us_toggle-button {
    top: 20px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 12px;
  }

  .m1-find-vehicle-us_toggle-button:first-of-type {
    left: 15px;
  }

  .m1-find-vehicle-us_toggle-button:nth-of-type(2) {
    right: 15px;
  }

  .m1-find-vehicle-us .title {
    margin-bottom: 15px;
  }

  body .m1-find-vehicle-us_toggle-button {
    /* font-size: 24px; */
    top: 13.5px;
  }

  body .m1-find-vehicle-us .title {
    font-size: 26px;
  }

  .m1-find-vehicle-us .m1-find-vehicle-us_big section + section {
    margin-top: 20px;
  }
}

@media screen and (max-width: 999px) and (min-width: 480px) {
  .m1-find-vehicle-us .m1-find-vehicle-us_big .cars_form_submit {
    margin-top: 10px;
  }

  .m1-find-vehicle-us:not(.small) select ~ select,
  .m1-find-vehicle-us:not(.small) input[name='zip'] {
    margin-left: 10px;
  }
}

@media screen and (max-width: 899px) {
  .m1-find-vehicle-us .m1-find-vehicle-us_small .cars_form_submit {
    margin: 10px 0 0;
    width: 100%;
  }

  .m1-find-vehicle-us_small .m1-find-vehicle-us_type-switcher {
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 575px) {
  .nt-hide-m {
    display: block;
  }

  .hide-m {
    display: none !important;
  }

  .magrin-b-null {
    margin-bottom: 0 !important;
  }

  .m1-find-vehicle-us_toggle-button {
    top: 11px;
  }
}

@media screen and (max-width: 479px) {
  .m1-find-vehicle-us .title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .m1-find-vehicle-us select {
    width: 100% !important;
  }

  .m1-find-vehicle-us select ~ select,
  .m1-find-vehicle-us input[name='zip'] {
    margin-top: 5px;
  }

  .m1-find-vehicle-us .m1-find-vehicle-us_small .cars_form_submit {
    margin-top: 5px;
  }

  .m1-find-vehicle-us_small .m1-find-vehicle-us_type-switcher {
    margin-bottom: 5px;
  }

  body .m1-find-vehicle-us .title {
    font-size: 22px;
  }
}

@media screen and (max-width: 379px) {
  .m1-find-vehicle-us_toggle-button {
    width: 10px;
    height: 10px;
    line-height: 10px;
    font-size: 8px;
  }

  body .m1-find-vehicle-us_toggle-button {
    top: 15px;
  }

  .m1-find-vehicle-us_toggle-button:first-of-type {
    left: 15px;
  }

  .m1-find-vehicle-us_toggle-button:nth-of-type(2) {
    right: 15px;
  }
}

.m1-find-vehicle-us.small {
  padding: 15px;
}

.m1-find-vehicle-us.small section + section {
  margin-top: 20px;
}

.m1-find-vehicle-us.small .title {
  font-size: 24px;
  margin-bottom: 10px;
}

.m1-find-vehicle-us.small select {
  width: 100% !important;
}

.m1-find-vehicle-us.small select ~ select,
.m1-find-vehicle-us.small input[name='zip'] {
  margin-top: 5px;
}

.m1-find-vehicle-us.small .m1-find-vehicle-us_type-switcher {
  display: none;
}

@media screen and (max-width: 350px) {
  body .m1-find-vehicle-us .title {
    font-size: 20px;
  }
}
