/* new design */

body[data-edtn='ev_us'] .m1-find-vehicle-eu.small .title {
    font-family: var(--vam-typeface-font-families-inside-evs-headings);
}

.m1-find-vehicle-eu .select2-container--disabled {
  visibility: visible;
  display: block;
}

.find-select__label {
    color: rgb(var(--vamc-text-contrast-soft));
    font-family: var(--vam-comfort-label-large-font-name);
    font-size: var(--vam-comfort-label-large-font-size);
    font-weight: var(--vam-comfort-label-large-font-weight);
    line-height: var(--vam-comfort-label-large-font-line-height);
    margin-bottom: var(--vam-spacing-sm-base);
    text-transform: uppercase;
}

.find-select__label-make:has(+ select[disabled]) {
    color: rgb(var(--vamc-text-contrast-muted));
}

.trending-models__list::-webkit-scrollbar {
    width: 0;
    height: 7px;
    opacity: 0;
}

.trending-models__list:hover::-webkit-scrollbar {
    width: 0;
    height: 7px;
    opacity: 1;
}

.trending-models__list::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 5px; 
}

.trending-models__list:hover::-webkit-scrollbar-thumb {
    background-color: rgb(var(--vamc-stroke-soft-200));
    border-radius: 10px; 
}
/* new design */